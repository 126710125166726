.job-title {
  font-size: 1rem;
  flex-basis: 80%;
  flex-shrink: 0;
}

.job-id {
  font-size: .9rem;
  color: #999;
}

.job-publish-date {
  font-size: .9rem;
  color: #999;
}

.job-location {
  font-size: .9rem;
  color: #999;
}

.job-details {
}
